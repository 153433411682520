(function ($, window)
{
    window.showHideElement = function (clickElement, targetElement) {

        var showMore = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'More';
        var showLess = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'Less';

        if (clickElement.attr('data-show-more') != '') {
            showMore = clickElement.attr('data-show-more');
        }

        if (clickElement.attr('data-show-less') != '') {
            showLess = clickElement.attr('data-show-less');
        }

        if (targetElement.is(':visible')) {
            targetElement.fadeOut('slow');
            clickElement.html(showMore);
        } else {
            targetElement.fadeIn('slow');
            clickElement.html(showLess);
        }

        return false;
    };
}(jQuery, window));

(function ($, window)
{
    window.progressBar = function (headingText, progress) {

    //    console.log('Progress: ' + headingText + ': ' + progress);
        if (progress >= 0 && $('#progressbar-around').is(':hidden')) {
            $('#progressbar-around').fadeIn('slow');
            $('#progressbar-heading').html(headingText);
        }

        $('#progressbar').css('width', progress + '%').attr('aria-valuenow', progress).text(progress + '%');

        if (progress >= 100 && $('#progressbar-around').is(':visible')) {
            $('#progressbar-around').fadeOut('slow');
        }
    };
}(jQuery, window));
